@use 'shared/breakpoints' as breakpoints;

$heading3-font-size: 30px !default;
$heading3-font-size--desktop: 36px !default;
$heading3-font-weight: 500 !default;

.heading3 {
  font-weight: $heading3-font-weight;
  font-size: $heading3-font-size;
  margin: 0;

  @media (min-width: breakpoints.$large) {
    font-size: $heading3-font-size--desktop;
  }
}
