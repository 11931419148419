.form-group {
  position: relative;

  &.dropdown-combo {
    .input-select {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
}
