@use 'shared/breakpoints' as breakpoints;

$heading5-font-size: 18px !default;
$heading5-font-size--desktop: 18px !default;
$heading5-font-weight: 500 !default;

.heading5 {
  font-weight: $heading5-font-weight;
  font-size: $heading5-font-size;
  margin: 0;

  @media (min-width: breakpoints.$large) {
    font-size: $heading5-font-size--desktop;
  }
}
