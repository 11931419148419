@use 'shared/breakpoints' as breakpoints;

$heading2-font-size: 36px !default;
$heading2-font-size--desktop: 40px !default;
$heading2-font-weight: 500 !default;

.heading2 {
  font-weight: $heading2-font-weight;
  font-size: $heading2-font-size;
  margin: 0;

  @media (min-width: breakpoints.$large) {
    font-size: $heading2-font-size--desktop;
  }
}
