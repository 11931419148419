@use 'shared/breakpoints' as breakpoints;

$heading6-font-size: 16px !default;
$heading6-font-size--desktop: 16px !default;
$heading6-font-weight: 500 !default;

.heading6 {
  font-weight: $heading6-font-weight;
  font-size: $heading6-font-size;
  margin: 0;

  @media (min-width: breakpoints.$large) {
    font-size: $heading6-font-size--desktop;
  }
}
