@use 'shared/breakpoints' as breakpoints;

$heading4-font-size: 24px !default;
$heading4-font-size--desktop: 24px !default;
$heading4-font-weight: 500 !default;

.heading4 {
  font-weight: $heading4-font-weight;
  font-size: $heading4-font-size;
  margin: 0;

  @media (min-width: breakpoints.$large) {
    font-size: $heading4-font-size--desktop;
  }
}
