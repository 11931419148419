:root {
  --default: transparent;
  --default-hover: white;
  --default-active: #c68b01;
  --default-contrast: black;
  --default-border: black;
  --default-border-active: black;

  --dark: black;
  --dark-hover: white;
  --dark-active: #c68b01;
  --dark-contrast: white;
  --dark-border: black;
  --dark-border-active: black;

  --disabled: transparent;
  --disabled-contrast: black;
  --disabled-border: #00000017;
}
