@import "shared/colors";

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  &__divider {
    width: 100%;
    background: var(--dark);
    border: 1px solid var(--dark);
  }

  .footer-content {
    display: flex;
    padding: 10px 20px;
    padding-bottom: 0;

    .refresh-btn {
      margin-left: auto;
      svg {
        font-size: 2rem;
        margin-top: 5px;
      }
    }
  }
  .footer-footer {
    text-align: center;
    font-size: 12px;
  }
}
