@use "shared/breakpoints" as breakpoints;
@import "shared/colors";

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  padding: 15px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &.default {
    color: var(--default-contrast);
    background-color: var(--default);
    border: 3px solid var(--default-border);

    &:hover {
      @media (min-width: breakpoints.$large) {
        background-color: var(--default-hover);
      }
    }

    &.active {
      border-color: var(--default-border-active);
    }
  }

  &.dark {
    color: var(--dark-contrast);
    background-color: var(--dark);
    border: 3px solid var(--dark-border);

    &:hover {
      @media (min-width: breakpoints.$large) {
        color: var(--dark);
        background-color: var(--dark-hover);
      }
    }

    &.active {
      border-color: var(--dark-border-active);
    }
  }

  &.ghost {
    color: black;
    background-color: rgba(0, 0, 0, 0.1);
    border: 3px solid transparent;

    &:hover {
      color: var(--dark);
      background-color: rgba(0, 0, 0, 0.14);
    }

    &.active {
      border-color: var(--dark-border-active);
    }
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &[disabled] {
    cursor: inherit;
    color: var(--disabled-contrast !important);
    background-color: var(--disabled !important);
    border: 3px solid var(--disabled-border !important);

    &:hover {
      color: var(--disabled-contrast !important);
      background-color: var(--disabled !important);
    }
  }
}
