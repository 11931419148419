.lang-popover {
  .MuiPopover-paper {
    border-radius: 10px;
  }
  .language-switcher-heading {
    font-size: 14px;
    line-height: 1.43;
    font-weight: 700;
    color: rgb(110, 117, 159);
    display: block;
    padding: 18px 18px 0px;
  }
  .MuiListItem-button {
    border-radius: 6px;
    margin: 1px 0px;
  }
  .active {
    color: rgb(34, 51, 84);
    background: rgb(242, 243, 255);
  }
}
