@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$font-primary: 'Roboto';
$font-fallbacks: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$font-family-primary: $font-primary, $font-fallbacks;

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-Thin.woff2') format('woff2'),
    url('../assets/fonts/Arboria-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-ThinItalic.woff2') format('woff2'),
    url('../assets/fonts/Arboria-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-Light.woff2') format('woff2'),
    url('../assets/fonts/Arboria-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-LightItalic.woff2') format('woff2'),
    url('../assets/fonts/Arboria-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-Book.woff2') format('woff2'),
    url('../assets/fonts/Arboria-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-BookItalic.woff2') format('woff2'),
    url('../assets/fonts/Arboria-BookItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-Medium.woff2') format('woff2'),
    url('../assets/fonts/Arboria-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-MediumItalic.woff2') format('woff2'),
    url('../assets/fonts/Arboria-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-Bold.woff2') format('woff2'),
    url('../assets/fonts/Arboria-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-BoldItalic.woff2') format('woff2'),
    url('../assets/fonts/Arboria-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-Black.woff2') format('woff2'),
    url('../assets/fonts/Arboria-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arboria';
  src: url('../assets/fonts/Arboria-BlackItalic.woff2') format('woff2'),
    url('../assets/fonts/Arboria-BlackItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
