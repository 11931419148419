.input-date {
  .MuiInput-input {
    font-size: 36px !important;
    font-weight: 500 !important;
    text-transform: uppercase;
  }

  .MuiFormLabel-root {
    font-size: 36px !important;
    font-weight: 500 !important;
    color: #00000040 !important;

    &.MuiFormLabel-filled,
    &.Mui-focused {
      transform: translate(0, 0) scale(0.4) !important;
      color: black !important;
    }
  }

  .MuiInput-underline {
    &:before {
      border-bottom: 3px solid black !important;
    }

    &:after {
      border-bottom: none !important;
    }
  }
}
