.input-select {
  :focus {
    background: transparent !important;
  }

  :before {
    border: none !important;
  }

  .MuiInput-underline {
    &:before {
      border-bottom: 3px solid black !important;
    }

    &:after {
      border-bottom: none !important;
    }
  }
}
