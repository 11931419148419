@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

$loader-animation: spin 1.5s linear infinite;

.loader {
  animation: $loader-animation;

  &.page-loading {
    margin: auto;
    flex-basis: unset;
  }
}
