@use 'shared/breakpoints' as breakpoints;

$heading1-font-size: 50px !default;
$heading1-font-size--desktop: 60px !default;
$heading1-font-weight: 500 !default;

.heading1 {
  font-weight: $heading1-font-weight;
  font-size: $heading1-font-size;
  margin: 0;

  @media (min-width: breakpoints.$large) {
    font-size: $heading1-font-size--desktop;
  }
}
